import makeFAIcon from "utils/makeFAIcon";

// import { faUmbrella, faFlagCheckered, faHome } from "@fortawesome/free-solid-svg-icons";

// export const ShoppingCartIcon = makeFAIcon(faShoppingCart);
// export const LaptopIcon = makeFAIcon(faLaptop);
// export const LockIcon = makeFAIcon(faLock);

// import { faUmbrella, faFlagCheckered, faHome } from "@fortawesome/free-solid-svg-icons";
// export const HomeIcon = makeFAIcon(faHome);
// export const FlagCheckeredIcon = makeFAIcon(faFlagCheckered);
// export const UmbrellaIcon = makeFAIcon(faUmbrella);

import { faHandHoldingHeart, faGift, faPeopleCarry } from "@fortawesome/free-solid-svg-icons";

export const GiftIcon = makeFAIcon(faGift);
export const HopeIcon = makeFAIcon(faHandHoldingHeart);
export const TeamIcon = makeFAIcon(faPeopleCarry);
